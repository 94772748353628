
  .slika{
    width: 400px;
    height: 100px
  }
  
  .loader {
    width: 100px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg); /* Inicijalna rotacija od 0 stepeni */
    transition: transform 2s linear; /* Animacija traje 2 sekunde */
  }
  
  .violin-neck {
    width: 8px;
    height: 100px;
    background-color: #000;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
  
  .violin-body {
    width: 100px;
    height: 200px;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50% 50% 0 0;
    transform: rotate(90deg);
  }
  
  /* Dodajte klasu za animaciju rotacije */
  .rotate {
    transform: translate(-50%, -50%) rotate(360deg); /* Rotira za 360 stepeni */
  }
  