.App {
  text-align: center;
  background-color: #1a191e !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* .css-1vw6mcs-MuiTypography-root{
  font-size: 1.5rem !important;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
img.slikaMob {
  max-width: 100%;
  height: auto;
  max-height: 193px;
  object-fit: cover; 
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Sakrij "webVarijanta" div na mobilnom uređaju i tabletu */
@media (max-width: 768px) {
  .webVarijanta {
    display: none;
  }
  .mobVerVij.MuiBox-root.css-i9gxme {
    padding-top: 0px !important;
}
  .makeStyles-root-29 {
    margin: 0px;
  }
  .MuiBox-root.css-i9gxme
  .makeStyles-gridItemRight-11 {
    margin-left: 1% !important ;
    padding-left: 0px !important;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.makeStyles-root-29.css-3su884-MuiPaper-root {
    width: 100%;
    margin-left: 2%;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: rgb(0, 0, 0) !important;
}
.samoNaMobilnoj {
  width: 100% !important;
  display: flex;
  position: absolute;
  background-color: white;
}

.mobVer {
  margin-left: 0 !important;
  padding: 4% !important;
}
.slikaMob {
  width: 100% !important;
}
.MuiBox-root.css-i9gxme {
  margin-left: 0px !important;
  margin-top: 14%;
}
.mobVerVij.MuiBox-root.css-i9gxme {
  margin-top: 0px;
  margin-left: 8% !important;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.makeStyles-gridItemRight-25.webVerzijaKontakt.css-1idn90j-MuiGrid-root {
  margin-top: 24%;
  background: #1a191e;
}
h5.MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom.makeStyles-slikaVijestNaslov-26.css-h93ljk-MuiTypography-root {
  background-color: #1a191e;
}
form {
  background-color: #1a191e;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.makeStyles-gridContainer-23.css-mhc70k-MuiGrid-root {
  background-color: #1a191e;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.makeStyles-root-32.contactFront.css-3su884-MuiPaper-root {
  width: 100% !important;
  margin-left: 0;
  margin-top: 0;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.webVerzijaKontakt.css-3su884-MuiPaper-root {
  margin-left: 15px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.makeStyles-root-56.contactFront.contactMobile.css-3su884-MuiPaper-root {
  width: 100%;
  margin-left: 2%;
}
}


@media (min-width: 768px) {

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.webVerzijaKontakt.css-3su884-MuiPaper-root {
    width: 50% !important;
    margin: auto;
    margin-top: 10% !important;
}
  /* .css-1vw6mcs-MuiTypography-root{
    font-size: 1.5rem !important;
} */
  form {
    background-color: #1a191e;
}
.kontaktWeb.MuiBox-root.css-i9gxme {
  margin-left: 14%;
  background-color: black;
  margin-top: 0px !important;
}
h5.MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom.makeStyles-slikaVijestNaslov-18.css-h93ljk-MuiTypography-root {
  background-color: #1a191e;
}
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.makeStyles-gridItemRight-47.webVerzijaBiografija.css-1idn90j-MuiGrid-root {
    margin-left: 22% !important;
}
  .makeStyles-gridItemRight-11 {
    /* margin-left: 25% !important ;
    padding-left: 0px !important; */
  }
  /* .makeStyles-gridItemRight-47 {
    margin-left: 25% !important;
  } */

  /* .gridItemRight {
    margin-left: 25% !important;
  } */
  /* .MuiBox-root.css-i9gxme {
    margin-left: 19%;
} */
/* .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 16px;
  margin-left: 40px;
}
.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 16px;
  margin-left: 0px;
}
.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  margin-left: 32px;
} */
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.makeStyles-gridItemRight-14.webVerzijaBiografija.css-1idn90j-MuiGrid-root {
  /* margin-left: 22% !important; */
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.makeStyles-gridItemRight-25.webVerzijaKontakt.css-1idn90j-MuiGrid-root {
  margin-left: -14% !important;
}


}